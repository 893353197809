import { Category } from '@/models/category';
import { Formula } from '@/models/formula';

const tableColumns = [
  {
    name: 'id',
    required: true,
    label: 'Código',
    align: 'left',
    field: (row: Formula): number | null => row.id,
    sortable: true,
  },
  {
    name: 'name',
    required: true,
    label: 'Nome',
    align: 'left',
    field: (row: Formula): string => row.name,
    sortable: true,
  },
  {
    name: 'description',
    required: true,
    label: 'Descrição',
    align: 'left',
    field: (row: Formula): string => row.description,
    format: (val: string | null): string => {
      if (val === null) {
        return '-';
      } else {
        return val.length > 20 ? `${val.substring(0, 20)}...` : val;
      }
    },
    sortable: true,
  },
  {
    name: 'category',
    required: true,
    label: 'Categoria',
    align: 'left',
    field: (row: Formula): Category => row.category,
    format: (val: Category): string => val.name,
    sortable: true,
  },
  {
    name: 'actions',
    label: '',
    align: 'right',
    field: (row: Formula): Formula => row,
    sortable: false,
  },
];

export { tableColumns };
