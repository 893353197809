import { Formula } from '@/models/formula';
import { FormulaSubstance } from '@/models/formula-substance';

interface FormulaForm {
  name: string;
  description: string;
  categoryId: number | null;
  userId: number | null;
  formulaSubstances: FormulaSubstance[];
}

interface FormulaFormToRequest {
  name: string;
  description: string;
  category_id: number | null;
  user_id: number | null;
  substances: { substance_id: number | null; value: number }[];
}

const getEmptyFormulaForm = (): FormulaForm => ({
  name: '',
  description: '',
  categoryId: null,
  userId: null,
  formulaSubstances: [],
});

const getFormByInstance = (formula: Formula): FormulaForm => ({
  name: formula.name,
  description: formula.description,
  categoryId: formula.categoryId,
  userId: formula.userId,
  formulaSubstances: formula.formulaSubstances,
});

const toRequestData = (formData: FormulaForm): FormulaFormToRequest => {
  return {
    name: formData.name,
    description: formData.description,
    category_id: formData.categoryId,
    user_id: formData.userId,
    substances: formData.formulaSubstances.map((formulaSubstance) => ({
      substance_id: formulaSubstance.substance?.id || null,
      value: formulaSubstance.value,
    })),
  };
};

export { getEmptyFormulaForm, getFormByInstance, toRequestData, FormulaForm };
