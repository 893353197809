import { Category } from './category';
import { FormulaSubstance } from './formula-substance';
import { Model } from './model';
import { Moment } from 'moment';
import { Expose, Type } from 'class-transformer';

class Formula extends Model {
  @Expose()
  public name: string;

  @Expose()
  public description: string;

  @Expose({ name: 'category_id' })
  public categoryId: number | null;

  @Expose({ name: 'user_id' })
  public userId: number | null;

  @Expose({ name: 'formula_substances' })
  @Type(() => FormulaSubstance)
  public formulaSubstances: FormulaSubstance[];

  @Expose()
  @Type(() => Category)
  public category: Category;

  constructor(
    id: number | null,
    createdAt: Moment,
    updatedAt: Moment,
    deletedAt: Moment,
    name: string,
    description: string,
    categoryId: number | null,
    userId: number | null,
    formulaSubstances: FormulaSubstance[],
    category: Category
  ) {
    super(id, createdAt, updatedAt, deletedAt);

    this.name = name;
    this.description = description;
    this.categoryId = categoryId;
    this.userId = userId;
    this.formulaSubstances = formulaSubstances;
    this.category = category;
  }
}

export { Formula };
