import { Substance } from './substance';
import { Expose, Type } from 'class-transformer';

class FormulaSubstance {
  @Expose()
  public id: number | null;

  @Expose()
  public value: number;

  @Expose({ name: 'formula_id' })
  public formulaId: number | null;

  @Expose({ name: 'substance_id' })
  public substanceId: number | null;

  @Expose({ name: 'substance' })
  @Type(() => Substance)
  public substance?: Substance;

  constructor(
    id: number | null,
    value: number,
    formulaId: number,
    substanceId: number,
    substance?: Substance
  ) {
    this.id = id;
    this.value = value;
    this.formulaId = formulaId;
    this.substanceId = substanceId;
    this.substance = substance;
  }
}

export { FormulaSubstance };
