import { Expose } from 'class-transformer';
import { Model } from './model';
import { Moment } from 'moment';

class Category extends Model {
  @Expose()
  public name: string;

  constructor(
    id: number | null,
    createdAt: Moment,
    updatedAt: Moment,
    deletedAt: Moment | null,
    name: string
  ) {
    super(id, createdAt, updatedAt, deletedAt);

    this.name = name;
  }
}

export { Category };
